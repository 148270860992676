@import url('https://fonts.googleapis.com/css?family=Dosis|Quicksand');

* {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;

}

.flex-gap>div {
  margin: 26px;
}